import { defineMessages } from 'react-intl';

export default defineMessages({
  resource1: {
    id: 'landingSeo.resources.resource1',
    defaultMessage: 'Guide complet sur les Cours particuliers',
  },
  resource2: {
    id: 'landingSeo.resources.resource2',
    defaultMessage: 'Guide complet sur les Professeurs particuliers',
  },
  resource3: {
    id: 'landingSeo.resources.resource3',
    defaultMessage: 'Guide complet sur le Soutien Scolaire',
  },
  resource4: {
    id: 'landingSeo.resources.resource4',
    defaultMessage: 'Guide complet sur les Stages intensifs',
  },
  resourceAlt1: {
    id: 'landingSeo.resources.resourceAlt1',
    defaultMessage: 'Lycéen qui prend des cours particuliers en ligne',
  },
  resourceAlt2: {
    id: 'landingSeo.resources.resourceAlt2',
    defaultMessage: 'Étudiant qui discute en visio avec son prof particulier',
  },
  resourceAlt3: {
    id: 'landingSeo.resources.resourceAlt3',
    defaultMessage: 'Photo d’une professeure de soutien scolaire',
  },
  resourceAlt4: {
    id: 'landingSeo.resources.resourceAlt4',
    defaultMessage: 'Déroulement d’un stage intensif à domicile',
  },
});
