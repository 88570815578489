import { FC } from 'react';
import {
  PublicGetSubjectPageResponse,
  SearchResult,
} from '@les-sherpas/sherpas-toolbox';

import Article from '@/components/LandingMozza/common/Article/Article';
import useGoToPageWithEvent from '@/components/LandingMozza/common/hooks/useGoToPageWithEvent';
import GTMEventName from '@/components/LandingMozza/LandingSeo/common/constants';
import SeoFight from '@/components/LandingMozza/LandingSeo/common/overloads/SeoFight/SeoFight';
import SeoHero from '@/components/LandingMozza/LandingSeo/common/overloads/SeoHero/SeoHero';
import SeoLandingFaq from '@/components/LandingMozza/LandingSeo/common/overloads/SeoLandingFaq/SeoLandingFaq';
import SeoResources from '@/components/LandingMozza/LandingSeo/common/overloads/SeoResources/SeoResources';
import SeoStartingSteps from '@/components/LandingMozza/LandingSeo/common/overloads/SeoStartingSteps/SeoStartingSteps';
import SeoStudentReviews from '@/components/LandingMozza/LandingSeo/common/overloads/SeoStudentReviews/SeoStudentReviews';
import SeoTeachers from '@/components/LandingMozza/LandingSeo/common/overloads/SeoTeachers/SeoTeachers';
import SeoTextBlock from '@/components/LandingMozza/LandingSeo/common/overloads/SeoTextBlock/SeoTextBlock';
import Breadcrumbs from '@/components/LandingMozza/LandingSeo/LandingSubject/Breadcrumbs/Breadcrumbs';
import SeoLinksSubjectTemplate from '@/components/LandingMozza/LandingSeo/LandingSubject/SeoLinksSubjectTemplate/SeoLinksSubjectTemplate';
import PaddingContainer from '@/components/PaddingContainer/PaddingContainer';
import { SEARCH } from '@/data/navbar/navButtons';

type Props = PublicGetSubjectPageResponse & {
  announces: SearchResult[];
};
const LandingSubject: FC<Props> = ({
  template,
  announces,
  subject: { name: subject },
  links,
}) => {
  const goToPageWithEvent = useGoToPageWithEvent();
  const searchQuery = { subject };
  const gtmEventData = {
    template: 'subject',
    subject,
  };

  return (
    <>
      <Article backgroundColor="greenGradient" verticalPadding={64}>
        <PaddingContainer>
          <div>
            <Breadcrumbs subject={subject} />
            <SeoHero hero={template.hero} />
          </div>
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={64}>
        <PaddingContainer>
          <SeoTeachers
            teachers={template.teachers}
            announces={announces}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'teachers.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white">
        <SeoFight fight={template.fight} />
      </Article>
      <Article backgroundColor="white" withOverflowHidden={false}>
        <PaddingContainer>
          <SeoStartingSteps
            stepper={template.stepper}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'stepper.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article
        itemScope
        itemType="https://schema.org/LocalBusiness"
        backgroundColor="blue"
        verticalPadding={120}
      >
        <PaddingContainer>
          <SeoStudentReviews reviews={template.reviews} />
        </PaddingContainer>
      </Article>
      <Article
        backgroundColor="white"
        itemScope
        itemType="https://schema.org/FAQPage"
        verticalPadding={64}
      >
        <PaddingContainer>
          <SeoLandingFaq
            qa={template.qa}
            handleRedirection={() => {
              goToPageWithEvent({
                route: SEARCH,
                query: searchQuery,
                event: GTMEventName,
                data: {
                  ...gtmEventData,
                  component: 'qa.searchButton',
                },
              });
            }}
          />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white" verticalPadding={120}>
        <PaddingContainer>
          <SeoResources guides={template.guides} />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="green" verticalPadding={64}>
        <PaddingContainer>
          <SeoLinksSubjectTemplate links={links} />
        </PaddingContainer>
      </Article>
      <Article backgroundColor="white">
        <PaddingContainer>
          <SeoTextBlock seo={template.seo} />
        </PaddingContainer>
      </Article>
    </>
  );
};

export default LandingSubject;
