const config = [
  {
    id: 'resource1',
    imageUrl:
      'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/service-image-1.png',
    link: 'https://sherpas.com/p/guide-cours-particuliers.html',
    altId: 'resourceAlt1',
  },
  {
    id: 'resource2',
    imageUrl:
      'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/prof-GiveCourses-stepper.jpg?w=2400&q=75&force_format=webp',
    link: 'https://sherpas.com/p/guide-professeur-particulier.html',
    altId: 'resourceAlt2',
  },
  {
    id: 'resource3',
    imageUrl:
      'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/service-image-3.png',
    link: 'https://sherpas.com/p/guide-soutien-scolaire.html',
    altId: 'resourceAlt3',
  },
  {
    id: 'resource4',
    imageUrl:
      'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/service-image-2.png',
    link: 'https://sherpas.com/p/guide-stages-intensifs.html',
    altId: 'resourceAlt4',
  },
];

export default config;
