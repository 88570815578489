import { ComponentProps, FC } from 'react';
import { useIntl } from 'react-intl';

import { ResourceItem } from '@/components/LandingMozza/LandingSeo/common/Resources/ResourcesGrid/ResourceItem/ResourceItem';
import { SEODefaultChunks } from '@/generic//SEOFormattedMessage/SEOFormattedMessage';

import messages from './messages';

import useStyles from './styles';

type ResourcesGridProps = {
  resources: ComponentProps<typeof ResourceItem>[];
};

export const ResourcesGrid: FC<ResourcesGridProps> = ({ resources }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      {resources.map(({ id, imageUrl, link, alt }) => (
        <ResourceItem
          key={id}
          id={id}
          title={formatMessage({ ...messages[id], ...SEODefaultChunks })}
          imageUrl={imageUrl}
          link={link}
          alt={alt}
        />
      ))}
    </div>
  );
};
