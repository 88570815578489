import { Title } from '@les-sherpas/sherpas-toolbox';

import { Resources } from '@/components/LandingMozza/LandingSeo/common/Resources/Resources';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoResources({ guides }: { guides: Title }) {
  return (
    <Resources
      title={
        guides.title ? (
          <SEOFormattedMessage
            id="resources.overloadedTitle"
            defaultMessage={guides.title}
          />
        ) : undefined
      }
    />
  );
}
