import { ComponentProps, FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import { ResourcesGrid } from '@/components/LandingMozza/LandingSeo/common/Resources/ResourcesGrid/ResourcesGrid';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import { SEODefaultChunks } from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

import config from './config';
import messages from './messages';

import useStyles from './styles';

type ResourcesProps = {
  title?: string | ReactElement;
  resources?: ComponentProps<typeof ResourcesGrid>['resources'];
};

export const Resources: FC<ResourcesProps> = ({ title, resources }) => {
  const { classes } = useStyles();

  const { formatMessage } = useIntl();
  const defaultResources = config.map(
    (resource) =>
      ({
        ...resource,
        title: formatMessage({
          ...messages[resource.id],
          ...SEODefaultChunks,
        }),
        alt: formatMessage({
          ...messages[resource.altId],
        }),
      }) satisfies ComponentProps<typeof ResourcesGrid>['resources'][number]
  );

  return (
    <div className={classes.container}>
      <ArticleHeader>
        <SectionTitle>
          {title ?? (
            <FormattedMessage
              {...messages.title}
              values={{ enter: EnterChunk }}
            />
          )}
        </SectionTitle>
      </ArticleHeader>
      <ResourcesGrid resources={resources ?? defaultResources} />
    </div>
  );
};
