import { FC } from 'react';
import { Typography } from '@mui/material';
import Image from 'next/image';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

type ResourceItemProps = {
  id: string;
  imageUrl: string;
  title: string;
  link: string;
  alt: string;
};

export const ResourceItem: FC<ResourceItemProps> = ({
  id,
  link,
  title,
  imageUrl,
  alt,
}) => {
  const { classes } = useStyles();

  return (
    <NextLinkWithNoCss
      key={id}
      href={link}
      target="_blank"
      className={classes.container}
    >
      <span className={classes.imageContainer}>
        <Image
          src={imageUrl}
          fill
          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
          loader={getImageFromCloudImage}
          quality={100}
          className={classes.image}
          // alt text is better left empty than using the title again as it would
          // causes a worse accessibility score due to a redundancy issue in the texts
          alt={alt}
        />
      </span>

      <Typography variant="bodyLgBold" component="p">
        {title}
      </Typography>
    </NextLinkWithNoCss>
  );
};
